.accordion {
  background-color: $header-color;
  cursor: pointer;
  padding: 1rem;
  width: 100%;
  border: 1px solid $link-color;  
  outline: none;
  transition: 0.4s;
  margin:-0.5rem 0 2rem 0;
  &:after {
    content: '\02795'; /* Unicode character for "plus" sign (+) */
    //color: $text-color;
    float: right;
    margin-left: 5px;
  }
}
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, /*.accordion:hover*/ {
  background-color: $text-color;
  color: white;
}
/*.accordion:hover {
  color:white!important;
  &:after{
    color:white!important;
  }
}*/
.active:after {
  content: "\2212";
  color: white;
  &:hover{
    color:white;
  }
}
.panel {
  padding: 0 0 1rem 0;
  display: none;
}



//=== masonry styling == //
.masonry-with-columns {
  columns: 4 200px;
  column-gap: 1rem;
  div {
    width: 150px;
    margin: 0 1rem 1rem 0;
    display: inline-block;
    width: 100%;
    text-align: left;
    font-family: system-ui;
    position:relative;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    background-position: center top;
    background-color: $ident-color;
    h3{
      position:absolute;
      bottom:0;
      left:0;
      right:0;
      padding:0.5rem;
      margin:0;
      z-index: 2;
      background-color: transparentize($ident-color, .2);
    }
    a{text-decoration: underline;}
  }
  @for $i from 1 through 36 {
    div:nth-child(#{$i}) {
      $h: (random(430) + 100) + px;
      height: $h;
      line-height: $h;
    }
  }
}
.project-columns {
  display: flex;
  flex-direction:row;
  justify-content:space-between;
  flex-wrap: wrap;
  div{
    width:48%;
    margin:0 0 1rem 0;
  }
}
.project-columns-half{
  display: flex;
  flex-direction:row;
  justify-content:space-between;
  flex-wrap: wrap;
  div{
    width:100%;
    margin:0 0 1rem 0;
  }
}

.masonry-london{
  background-image: url('../img/thethemeislondon.jpg');
}
.masonry-rootsmanuva{
  background-image: url('../img/rootsmanuva.jpg');
}
.masonry-tatekids{
  background-image: url('../img/tatekids.jpg');
}
.masonry-life{
  background-image: url('../img/life.jpg');
}
.masonry-seujorge{
  background-image: url('../img/seujorge.jpg');
}
.masonry-nhs{
  background-image: url('../img/nhs.png');
}
.masonry-mekamorphis{
  background-image: url('../img/mekamorphis.jpg');
}
.masonry-eggs{
  background-image: url('../img/eggs.jpg');
}
.masonry-deadline{
  background-image: url('../img/deadline.jpg');
}
.masonry-shat{
  background-image: url('../img/shat.jpg');
}
.masonry-dream16{
  background-image: url('../img/dream16.jpg');
}
.masonry-carla{
  background-image: url('../img/carlabruni.jpg');
}
.masonry-leka{
  background-image: url('../img/leka.jpg');
}
.masonry-badu{
  background-image: url('../img/erykahbadu.jpg');
}
.masonry-monsters{
  background-image: url('../img/monsters.jpg');
}
.masonry-eda{
  background-image: url('../img/edastudy.jpg');
}
.masonry-fire{
  background-image: url('../img/actasif.jpg');
}
.masonry-yrs{
  background-image: url('../img/yrs.png');
}
.masonry-dj{
  background-image: url('../img/dj.jpg');
}
.masonry-sybil{
  background-image: url('../img/sybil.jpg');
}
.masonry-apocalypse{
  background-image: url('../img/homobulla.jpg');
}
.masonry-yokeda{
  background-image: url('../img/yokeda.jpg');
}
.masonry-flying{
  background-image: url('../img/flyinglotus.jpg');
}
.masonry-macaroon{
  background-image: url('../img/macaroon.jpg');
}
.masonry-vsMs{
  background-image: url('../img/vsMS.jpg');
}



// === blog related === //

.post-list{
	li{
		margin:0 0 1rem 0;
	}
}

ul.index{
	li{
		margin:0 0 2rem 0;
	}
}

// ========================== 720px + ==================
@media (min-width: 720px){
  .accordion {
    width:75%;
  }
  .project-columns {
    flex-direction:row;
    justify-content:space-between;
    div{
      width:48%;
    }
  }
  .project-columns-half{
    div{
      width:48%;
    }
  }
}
// ========================== 1024px + ==================
@media (min-width: 1024px){
  .project-columns {
    div{
      width:24%;
    }
  }
}
