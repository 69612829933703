// ==== resets === // 
*, html {
  box-sizing: border-box;
}
*,*:before,*:after{box-sizing:inherit}

body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0; 
  font-weight:normal
}

ul, ol, dl, li{
  list-style-type: none;
}
/**
 * Basic styling
 */
body {
  font-family: 'Open Sans', sans-serif;
  line-height: 150%;
  direction: ltr;
  -webkit-font-variant-ligatures: no-common-ligatures;
  font-variant-ligatures: no-common-ligatures;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  box-sizing: border-box;
  }