/* specific print rules */

@media print { 
	#rford-description, #rford-menu, .rford-contact{
		display: none!important;
	}
	.rford-ident {
    	margin:0 0 2rem 0;
	}
	.rford-intro p, #rford-description p {
    	width: 100%;
	}
	.rford-project{
	  display: flex;
	  -ms-flex-direction:row;
	  flex-direction: row;
	  > div:first-child{
		  	padding:0 2rem 1rem 0;
			width:60%;
	  }
	  .image{
	  	width:40%;
	  }
	} 
}

