//==== page ==== //

body {
	margin: 0;
	padding: 0;
	width: 100%;
	background-color: $body-color;
}
a, a:link{
	color:$link-color;
}



