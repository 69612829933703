// ==== typography ==== //
$rem-base-font: 16px !default;
@mixin font-rem($font-size, $line-height: 0) {
	font-size: $font-size;
	font-size: ($font-size / $rem-base-font) * 1rem;
	@if ($line-height > 0) {
		line-height: $line-height;
		line-height: ($line-height / $rem-base-font) * 1rem;
  }
}

@font-face{font-family:'bebas-neue';src:url("../fonts/BebasNeue-Regular.eot");
src:url("../fonts/BebasNeue-Regular.eot#iefix") format("embedded-opentype"),
url("../fonts/BebasNeue-Regular.ttf") format("truetype"),
url("../fonts/BebasNeue-Regular.woff") format("woff"),
url("../fonts/BebasNeue-Regular.otf") format("otf");
font-weight:normal;font-style:normal}

@font-face{font-family:'lato-regular';
src:url("../fonts/Lato-Regular.eot");
src:url("../fonts/Lato-Regular.eot#iefix") format("embedded-opentype"),
url("../fonts/Lato-Regular.ttf") format("truetype"),
url("../fonts/Lato-Regular.woff") format("woff");
font-weight:normal;font-style:normal}

@font-face{font-family:'lato-light';
src:url("../fonts/Lato-Light.eot");
src:url("../fonts/Lato-Light.eot#iefix") format("embedded-opentype"),
url("../fonts/Lato-Light.ttf") format("truetype"),
url("../fonts/Lato-Light.woff") format("woff");
font-weight:normal;font-style:normal}

@font-face{font-family:'icomoon';
src:url("../fonts/icomoon.eot?4h56v5");
src:url("../fonts/icomoon.eot?4h56v5#iefix") format("embedded-opentype"),
url("../fonts/icomoon.ttf?4h56v5") format("truetype"),
url("../fonts/icomoon.woff?4h56v5") format("woff"),
url("../fonts/icomoon.svg?4h56v5#icomoon") format("svg");
font-weight:normal;font-style:normal}

[class^="icon-"],[class*=" icon-"],
.rford-contact--nav .rford-contact--links li a
	{
		font-family:'icomoon' !important;
		speak:none;
		font-style:normal;
		font-weight:normal;
		font-variant:normal;
		text-transform:none;
		line-height:1;
		-webkit-font-smoothing:antialiased;
		-moz-osx-font-smoothing:grayscale;
}


.rford-contact--nav ul li a{
		color:$ident-color;
}
.rford-contact--nav ul li a:before{
	width:50px;height:50px;
}
.rford-contact--links{
	@include font-rem(14px,21px);
	color:$ident-color;
	a{
		color:$ident-color;
	}
	svg{
		color:$ident-color;
		height: 1rem;
		width: 1rem;
		fill:$ident-color;
	}
}
.icon-medium:before{
	content:"\e901";
}
.icon-mailchimp:before{
	content:"\e900";
}
.icon-phone:before{
	content:"\e942";
}
.icon-menu:before{
	content:"\e9bd";
}
.icon-cross:before{
	content:"\ea0f";
}
.icon-share:before{
	content:"\ea7d";
}.icon-mail:before{
	content:"\ea83";
}
.icon-instagram:before{
	content:"\ea92";
}
.icon-whatsapp:before{
	content:"\ea93";
}
.icon-slack:before{
	content:"\e902";
}
.icon-twitter:before{
	content:"\ea96";
}
.icon-dribbble:before{
	content:"\eaa7";
}
.icon-behance:before{
	content:"\eaa8";
}
.icon-github:before{
	content:"\eab0";
}
.icon-linkedin:before{
	content:"\eaca";
}
.icon-pinterest:before{
	content:"\ead1";
}

body{
	font-family:"lato-regular",sans-serif;
}
/*h1,*/h2,.rford-ident--name a{
	font-family:"bebas-neue",sans-serif;
}
.rford-ident--global_nav{
	//font-family:"lato-light",sans-serif;
}

%uppercase{
	text-transform: uppercase;
}




// ============================
// set the default font sizes
// ============================

.rford-ident--name a{
	text-decoration:none;
	@include font-rem(36px,42px);
}
.rford-ident--menu{
	@include font-rem(32px,32px);

	a{
		text-decoration: none;
	}
}
.rford-ident--global_nav{
	font-family: "Lato-Regular", sans-serif;
	@include font-rem(18px,21px);
	font-weight:400;
	li{
		padding:0 0 0.75rem 0;
		&:last-child{
			padding:0;
		}
		a{
			padding-bottom:0.125rem;
  			display: inline-block;
  			line-height: 0.85;
  			text-decoration:none;
		}
		&.nav-active{
			font-family: "lato-regular", sans-serif;
		}
	}

}
#rford-description{
	@include font-rem(18px,36px);
	p{
		margin:0 0  0.5rem 0;
	}
}

h1, h2, h3, h4, h5, h6{
	font-weight:400;
}
h1{
	text-transform: capitalize;
}
h2, h3{
	a{
		text-decoration: none;
	}
}
h2{
	@include font-rem(24px,28px);
}
h3{
	@include font-rem(14px,18px);
}

.masonry-with-columns{
	h3{
		span{
			font-size: 60%;
		}
	}
}

.rford-intro{
	padding:1.25rem 0 1.25rem 0;
	p{
		@include font-rem(18px,28px);
		margin:0 0 1rem 0
	}
}

.rford--list--skills{
	margin:0 0 1rem 3rem;
	li{
		list-style-type: disc;
		padding:0 0 0.5rem 0;
	}
}
.rford--list--client{
	> li {
		h3 {
			@include font-rem(21px,28px);
			margin:0 0 0.25rem 0;
		}
		ul li{
			list-style-type: disc;
		}
	}
}
button, .accordion{
	text-align: left;
	font-weight: normal;
}

.rford-contact--nav{
	font-size:16px;
	font-size:1rem
}
.post-list{
	h3{
		@extend %uppercase;
	}
}
.post-meta{
	@include font-rem(14px, 18px);
}

#intro_text{
	@include font-rem(21px, 32px);
	p{
		margin:0 0 1rem 0;
	}
}
.aside{
	.promo_boxes{
		li{
			h3{
				@extend %uppercase;
				font-weight: 600;
			}
		}
	}
}
.disclosure{
	h4{
		font-weight:600;
	}
	@include font-rem(14px, 21px);
	padding:1rem;
}

//================= min-width 320 ================//

@include respond-to(mobile, true) {
	h1{
		@include font-rem(32px, 36px);
	}
	h2{
		@include font-rem(24px, 32px);
		margin:0 0 1rem 0;
	}
	h3{
		@include font-rem(21px, 28px);
	}
	#intro_text{
		@include font-rem(21px, 28px);
		padding:0 0 1rem 0;
	}
	main{
		article{
			p{
				@include font-rem(18px, 26px);
				margin:0 0 1rem 0;
			}
		}
	}
	.button, .accordion{
		@include font-rem(18px, 26px);
	}
}

// ============ min-width 720 =================//

@include respond-to(agnostic, true) {
	h1{
		@include font-rem(32px, 36px);
	}
	h2{
		@include font-rem(24px, 32px);
		margin:0 0 1.25rem 0;
	}
	h3{
		@include font-rem(21px, 28px);
	}
	#rford-description{
		@include font-rem(24px,36px);
	}
	.rford-intro{
		padding:0 0 2rem 0;
		p{
			@include font-rem(21px,32px);
		}
	}
	main{
		article{
			p{
				@include font-rem(18px, 26px);
				margin:0 0 1rem 0;
			}
		}
	}
	.rford-ident--name p{
		width:75vw
	}
	.rford-ident--global_header{
		font-size:18px;
		font-size:1.125rem;
		margin:0 0 1.5rem 0;
	}
	.accordion {
		@include font-rem(21px, 28px);
	}

	.rford--list--skills{
		li{
			font-size:16px;
			font-size:1rem;
		}
	}
	.rford--list--client{
		> li h3{
			margin:0 0 0.25rem 0;
			font-size:21px;
			font-size:1.3125rem;
		}
	}
	.rford-contact--nav{
		font-size:18px;
		font-size:1.125rem;
	}
}

// ================== min-width 1024 ====================//
@include respond-to(large_tablet, true) {
	.rford-ident--global_header p{
		@include font-rem(24px, 32px);
		width:75%;
	}
	#rford-description{
		@include font-rem(24px,40px);
	}
	.accordion {
		@include font-rem(24px, 32px);
	}
	.rford-intro{
		padding:1rem 0 2rem 0;
		p{
			@include font-rem(24px,32px);
			margin:0 0 2rem 0;
		}
	}
	.rford--list--client>li h3{
		margin:0 0 0.5rem 0;
		@include font-rem(24px, 28px);
	}

	.rford-contact--nav{
		@include font-rem(21px, 28px);
	}


}

// ================== min-width 1280 ====================//

@include respond-to(largest_width, true) {
	h1{
		@include font-rem(32px, 36px);
		padding:0 0 2.5rem 0;
	}
	h2{
		@include font-rem(24px, 32px);
		margin:0 0 1.5rem 0;
	}
	h3{
		@include font-rem(18px, 24px);
	}
	.rford-intro{
		@include font-rem(26px, 34px);
		padding:0 0 1.75rem 0;
	}
	main{
		article{
			p{
				@include font-rem(21px, 28px);
				margin:0 0 1rem 0;
			}
		}
	}
}


// ============ min-width 720 =================//


/*
// ================== min-width 1024 ====================//

@media (min-width: 1024px){

}


// ================== min-width 1200 ====================//
@media (min-width: 1200px){
	.rford-ident--name a{
		font-size:36px;
		font-size:2.25rem
	}
	#rford-description{
		@include font-rem(32px,40px);
	}
	.rford-intro{
		padding:1rem 0 3rem 0;
	}
	.rford-intro p{
		font-size:21px;
		font-size:1.3125rem;
	}
}

*/
