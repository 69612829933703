// === basics sass file ==//

// === breakpoints === /


@mixin respond-to($media, $retina: true) {
    // Settings
    $base_width:0;
    $mobile: 20rem; //320
    $agnostic: 45rem; //720
    $tablet:48rem; //768
    $large_tablet:64rem; //1024
    $largest_width:80rem; //1280
	
	@if $media == 'mobile_less' {
		@if $retina == true {
			@media
			only screen and (-webkit-min-device-pixel-ratio: 2)      and (max-width: #{$mobile} -1),
			only screen and (   min--moz-device-pixel-ratio: 2)      and (max-width: #{$mobile} -1),
			only screen and (     -o-min-device-pixel-ratio: 2/1)    and (max-width: #{$mobile} -1),
			only screen and (        min-device-pixel-ratio: 2)      and (max-width: #{$mobile} -1),
			only screen and (                min-resolution: 192dpi) and (max-width: #{$mobile} -1),
			only screen and (                min-resolution: 2dppx)  and (max-width: #{$mobile} -1) { @content }
	    	} @else {
				@media only screen and (max-width: #{$mobile}  -1) { @content }
			}
	} @else if $media == 'mobile' {
		@if $retina == true {
			@media
			only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: #{$mobile}),
			only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: #{$mobile}),
			only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: #{$mobile}),
			only screen and (        min-device-pixel-ratio: 2)      and (min-width: #{$mobile}),
			only screen and (                min-resolution: 192dpi) and (min-width: #{$mobile}),
			only screen and (                min-resolution: 2dppx)  and (min-width: #{$mobile}) { @content }
		} @else {
			@media only screen and (min-width: #{$mobile}) { @content }
		}		
	} @else if $media == 'agnostic' {
		@if $retina == true {
			@media
			only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: #{$agnostic}),
			only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: #{$agnostic}),
			only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: #{$agnostic}),
			only screen and (        min-device-pixel-ratio: 2)      and (min-width: #{$agnostic}),
			only screen and (                min-resolution: 192dpi) and (min-width: #{$agnostic}),
			only screen and (                min-resolution: 2dppx)  and (min-width: #{$agnostic}) { @content }
		} @else {
			@media only screen and (min-width: #{$agnostic}) { @content }
		}
	} @else if $media == 'tablet' {
		@if $retina == true {
			@media
			only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: #{$tablet}),
			only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: #{$tablet}),
			only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: #{$tablet}),
			only screen and (        min-device-pixel-ratio: 2)      and (min-width: #{$tablet}),
			only screen and (                min-resolution: 192dpi) and (min-width: #{$tablet}),
			only screen and (                min-resolution: 2dppx)  and (min-width: #{$tablet}) { @content }
		} @else {
			@media only screen and (min-width: #{$tablet}) { @content }
		}
	} @else if $media == 'large_tablet' {
		@if $retina == true {
			@media
			only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: #{$tablet}),
			only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: #{$tablet}),
			only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: #{$tablet}),
			only screen and (        min-device-pixel-ratio: 2)      and (min-width: #{$tablet}),
			only screen and (                min-resolution: 192dpi) and (min-width: #{$tablet}),
			only screen and (                min-resolution: 2dppx)  and (min-width: #{$tablet}) { @content }
		} @else {
			@media only screen and (min-width: #{$tablet}) { @content }
		}		
	} @else if $media == 'largest_width' {
			@if $retina == true {
				@media
				only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: #{$largest_width}),
				only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: #{$largest_width}),
				only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: #{$largest_width}),
				only screen and (        min-device-pixel-ratio: 2)      and (min-width: #{$largest_width}),
				only screen and (                min-resolution: 192dpi) and (min-width: #{$largest_width}),
				only screen and (                min-resolution: 2dppx)  and (min-width: #{$largest_width}) { @content }
			} @else {
			@media only screen and (min-width: #{$largest_width}) { @content }
	    }
    }
}


%h-e {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  width: 0.0625rem !important;
  display: none!important;
  margin-bottom:0!important;
  margin:0!important;
}
.h-e{
	@extend %h-e;
}

