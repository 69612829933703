@media (min-width: 0px){
	main,.container{max-width:90%}
	.main{margin:0.5rem 0}
}

@media (min-width: 321px){
	main,.container{max-width:90%}
}

@media (min-width: 720px){
	main,.container{
		max-width:95%;
		width:100vw;
	}
	.main{
		margin:1rem 0;
	}
}

@media (min-width: 1024px){
	main,.container{max-width:95%}
	.main{margin:2rem 0}
}

@media (min-width: 1200px){
	main,.container{
		max-width:1024px;
	}
}


p{
	margin:0 0 1rem 0;
}
img{
	max-width:100%;
	height:auto;
}
html,body{
	height:100%;
	margin:0;
	padding:0;
}
.a11y{
	position:absolute !important;
	height:1px;width:1px;
	overflow:hidden;
	clip:rect(1px 1px 1px 1px);
	clip:rect(1px, 1px, 1px, 1px);
}

body{
	color:#222;
	a, a:link{
		color:#222;
	}
}

h1{margin:0 0 1rem 0}
h2{margin:0 0 1rem 0}
h3{margin:0 0 1rem 0}

.rford-intro{margin:0 0 1rem 0}
.rford--list{
	li {line-height:1.5rem}
}
 
.rford--list--client{
	 > li h3{margin:0 0 0.25rem 0}
	 > li p {margin:0 0 0.5rem 0}
	 > li ul li a{font-weight:600;color:black}
}

.rford-legal{
	li{
		font-size:14px;
		font-size:.875rem;
		font-family:"lato-regular",sans-serif;
		a{
			font-family:"lato-regular",sans-serif !important;
			text-decoration:underline}
	}
} 

main,.container{
	margin:0 auto
}
footer{
	padding:2rem 0 4rem 0;
	background:#222;
	color:$header-color;
	a{
		color:$header-color;
	}
	ul li a{
		text-decoration:none;
	}
}
.rford-ident--container{
	position:relative;
}
header.rford-ident{
	position:relative;
	background-color:$header-color;
	padding:2rem 0 1.25rem 0;
}
#rford-menu{
	//position:relative;
	//display: flex;
	//flex-direction: row;
	//background-color:lighten($header-color,10%);
}
.rford-ident--global_header{
	//display:-ms-flexbox;
	//display:flex;
	//justify-content:space-between;
	
}
.rford-ident--name{
	margin:0;
	padding:0;
}

.rford-ident--global_nav{
	border-bottom:1px solid #999;
	//display:none;
	//padding:1rem 0;
	//margin:0 0 2rem 0;
	//width:100%;
	//transition:5s;
	//transition-timing-function:ease-out;
	&.responsive{
		//display:block;
		//transition:5s;
		//transition-timing-function:ease-out;
	}
	ul{
		list-style-type:none;
		display: flex;
		flex-direction: row;
		margin:1rem 0;
		li{
			//padding:0.5rem 0;
			//border-bottom:1px dotted #222;
			padding:0 1rem 0 0;
			a{
				text-decoration: none;
			}
		}
		&:first-child{
			//padding:0 0 0.5rem 0;
		}
		&:nth-child(2){
			background-color:white;
		}
		/*&:nth-child(odd){
			background-color:red;
		}*/
	}
}
.rford-contact--nav {
	ul{
		list-style-type:none;
	}
	.rford-contact--links{
		display:-ms-flexbox;
		display:flex;-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		-ms-flex-pack:start;
		justify-content:
		flex-start;
		margin-bottom:1.5rem;
		li{
			padding:0 1rem 0 0;
		}
	}
}
.rford-legal{
	display:block;
	li{
		-ms-flex-direction:column;
		flex-direction:column;
	}
}
#rford-top{
	margin:0 0 1rem 0;
}
.main ul li ul{
	margin:0 0 1rem 0;
}
.rford-list p{
	margin:0 0 1rem 0;
}
.rford--list--skills{
	margin:0 0 0 1rem;
}
.rford--list--client{
	> li{
		display:-ms-flexbox;
		display:flex;
		border-bottom:1px dotted #222;
		&:last-child{
			border-bottom:0;padding:1rem 0 0 0;
		}
		p{
			margin:0 0 0.5rem 0;
		}
		ul li{
			border-bottom:0;
			padding:0;
			margin:0 0 0.5rem 2rem;
		}
	}
}
.rford-list--project{
	display:-ms-flexbox;display:flex;
	li{
		-ms-flex-pack:justify;justify-content:space-between;
	}
}
.rford--list--client > li{
	padding:1rem 0;
	-ms-flex-direction:column;
	flex-direction:column;
	&:first-child{
		padding:0 0 1rem 0;
	}
}
.rford--list--client > li div:first-child{
	margin-bottom:1rem;
	max-width:40%;
}
.rford-list--project li{width:100%}

.rford-project{
	display: flex;
	-ms-flex-direction:column;
	flex-direction:column;
	margin:0 0 1rem 0;
	> div:first-child{
		padding:0 0 2rem 0;
	}
	.image{

	}
}

// ========================== 321px + ==================
@media (min-width: 321px){
	.rford--list--client{
		width:100%;
	}
}

// ========================== 720px + ==================
@media (min-width: 720px){
	header.rford-ident{
		padding:2rem 0 1rem 0;
	}
	.rford-intro, #rford-description{
		p{
			width:75%;
		}
	} 
	.rford--list--client{
		>li{
			padding:1rem 0;
			-ms-flex-direction:row;
			flex-direction:row;
			div+div{
				width:80%;
				padding:1rem 0 0 1rem;
				-ms-flex-align:end;
				align-items:flex-end;
				-ms-flex-line-pack:stretch;
				align-content:stretch;
			}
		}
	}
	.rford-list--project{
		li{
			width:50%;
			margin-bottom:0;
		}
		 > li div:first-child{
			max-width:25%;
			margin-right:1rem;
		}
	}
	.rford-project{
	  display: flex;
	  -ms-flex-direction:row;
	  flex-direction: row;
	  > div:first-child{
		  	padding:0 2rem 1rem 0;
			width:60%;
	  }
	  .image{
	  	width:40%;
	  }
	} 
}
// ========================== 1024px + ==================
@media (min-width: 1024px){
	.rford--list--client{
		width:75%;
	}
	.rford--list--client{
		> li{
			div+div{
				width:75%;
			}
		} 
	}	
	.rford-list--project{
		li{
			width:33%;
		}
	} 
}

// ========================== 1200px + ==================
@media (min-width: 1200px){
	.rford-list--project{
		li{
			img{
				width:25%;
			}
		} 
	} 
}










