
// ==== colours === //

$body-color: #ffffff;
$text-color:#313131;
$link-color:#212121;
$ident-color:#ffffff;

$pantone_coty:#FA7268;
$header-color: #ffffff;


// === raspberry tones === //

$turq: #a1c3d1;
$mauve: #b39bc8;
$off_white: #f0ebf4;
$pink: #f172a1;
$raspberry: #e64398;
$dk_raspberry:#a71663;

// == section colours == //

$section_one:$dk_raspberry;
$section_one_text:$off_white;
$section_two:$turq;
$section_two_text:$text-color;

%colour_default{
	background:$off_white;
	colour:$text-color;
	a, a:link{
		colour:$text-color;
	}
}
%colour_one{
	color:$section_one_text;
	background:$section_one;
	a, a:link{
		colour:$section_one_text;
	}
}

%colour_two{
	color:section_two_text;
	background:$section_two;
	a, a:link{
		colour:$section_two_text;
	}
}
%border_bottom{
	border-bottom:1px solid lighten($ident-color, 10%);
}
.site-header{
	box-shadow: 0 3px 3px rgba(0,153,153,0.2);
}
.disclosure{
	background-color:lighten($text-color,35%);
	color:white;

}
.content{
	.wrapper{
		background: white;
	}
}
footer{
	background:$ident-color;
}
section.one{
	@extend %colour_one;
}
section.two{
	@extend %colour_two;
}
